.single__building {
  &-hero {
    background-color: var(--primary-dark);
    padding-top: 2em;
  }
  &-banner {
    display: flex;
    flex-wrap: wrap;

    .slick-slide {
      height: clamp(30em, 60vh, 40em);
      margin: 0 20px;
      position: relative;
      &:before {
        opacity: 0;
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        background-color: white;
        position: absolute;
        width: 100%;
        height: 100%;
        transition: all 1.2s;
      }
      &:not(.slick-center) {
        &:before {
          opacity: 0.6;
        }
      }
    }
    .slick-slide img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-info{
    padding: 2em 1em;
    color: white;
    .container {
      @include for-size(tablet-portrait-up) {
        display: grid;
        flex-wrap: wrap;
        grid-template-columns: minmax(auto, 480px) minmax(auto, 365px) 1.3fr;
        align-items: center;
      }
    }

    &-flag {
      background-color: var(--primary-light);
      color: var(--primary);
      text-align: center;
      align-items: center;
      display: inline-flex;
      justify-content: center;
      padding: 10px 20px;
      border-radius: 100px;
      font-size: 11px;
      font-weight: 600;
      margin-bottom: 10px;
      .icon {
        --size: 18px;
      }
    }
    &-heading {
      text-align: center;
    }
    &-logo {
      margin: 0 auto;
      text-align: center;
      img {
        height: 80px;
      }
      @include for-size(tablet-portrait-down) {
        margin: 0;
        display: inline-flex;
      }
    }
    &-title {
      font-size: 2em;
    }
    &-description {
      padding-bottom: 20px;
      margin: 0;
      margin-top: 10px;
      font-weight: 600;
    }
    &-price {
      font-size: 15px;
      padding: 1.4em 0.5em;
      font-weight: 200;
      color: var(--gray-6);
      @include for-size(tablet-portrait-up) {
        border-left: 1px solid #1b6986;
        padding-left: 25px;
      }
      &--value {
        color: white;
        font-size: 2.2em;
      }
      .btn-link {
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        font-weight: 500;
        position: relative;
        svg {
          margin-right: 10px;
        }
        &:hover {
          .payments {
            display: block;
          }
        }
        .payments {
          background-color: white;
          position: absolute;
          top: 30px;
          width: 280px;
          left: -30px;
          padding: 20px;
          display: none;
          z-index: 10;
          box-shadow: 0px 6px 8px rgb(51 51 51 / 10%);
          &:after {
            content: "";
            top: -21px;
            left: 0;
            right: 0;
            margin: auto;
            width: 1px;
            height: 1px;
            position: absolute;
            border-right: 10px solid transparent;
            border-left: 10px solid transparent;
            border-top: 10px solid transparent;
            border-bottom: 10px solid #f7f7fc;
          }
          &:before {
            content: "";
            background-color: transparent;
            top: -16px;
            height: 30px;
            width: 100%;
            position: absolute;
          }

          &__list {
            display: flex;
            flex-wrap: wrap;
            margin: 0;
          }
          &__item {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 0;
            font-size: 13px;
            letter-spacing: 0.75px;
            color: #6E7191;
            margin-bottom: 20px;
          }
          .icon {
            --size: 15px;
            fill: var(--primary-dark);
          }
        }

      }
    }
    .single__page-list {
      padding: 1.6em 0;
      width: 100%;
      justify-content: center;
    }
    .single__page-list .icon {
      --size: 20px;
      @include for-size(big-desktop-up) {
        --size: 50px;
      }
    }
    .single__page-label {
      font-size: 12px;
      margin: 5px 0;
      @include for-size(big-desktop-up) {
        font-size: 18px;
      }
    }
    .single__page-item {
      margin-right: 0;
      margin-top: 0;
    }
    .btn {
      margin-top: 10px;
      @include for-size(tablet-portrait-down) {
        width: 100%;
        text-align: center;
        justify-content: center;
        --padding: 15px 20px;
        margin: 0 1em;
      }
      @include for-size(big-desktop-up) {
        --padding: 15px 40px;
        --font-size: 20px;
      }
    }
  }
  &-features {
    display: flex;
    flex-wrap: wrap;
    @include for-size(tablet-portrait-down) {
      width: 100%;
    }

    &-item {
      font-size: 13px;
      text-align: center;
      font-size: 13px;
      padding: 0 10px;
      .icon {
        fill: white;
      }
    }
  }
  &-actions {
    margin-top: 2em;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    .btn__link {
      color: var(--gray-2);
      margin-left: 20px;
      display: inline-flex;
      align-items: center;
      @include for-size(tablet-portrait-down) {
        margin-top: 15px;
      }
      svg {
        margin-right: 15px;
      }
    }
  }
}

.single__building-about {
  padding-bottom: 1em;
  .heading__title {
    font-size: max(2em, 2vw);
    margin-top: 0;
    margin-bottom: 1em;
    margin-top: 1em;
    font-weight: 700;
  }

  &-date {
    display: flex;
    flex-wrap: wrap;
    color: var(--gray-2);
    font-size: 14px;
  }
  &-container {
    width: 100%;
    margin-top: 3em;
    @include for-size(tablet-portrait-up) {
      display: grid;
      grid-gap: 3em;
      grid-template-columns: 1fr 2fr;
    }
  }
  &-description {
    color: var(--gray-2);
    font-size: 16px;
    line-height: 28px;
    width: 100%;
    &:not(.active) {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
    }
    &.active ~ .show-more .icon {
      transform: rotate(180deg);
    }
  }
  .show-more {
    display: block;
    text-align: center;
    position: relative;
    span {
      font-weight: 600;
      display: inline-block;
      background-color: white;
      padding: 4px 10px;
      color: var(--gray-2);
      font-size: 12px;
      z-index: 2;
      position: relative;
      border-top: 0;
    }

    &:before {
      content: '';
      text-align: center;
      top: -30px;
      bottom: 0;
      left: 0;
      width: 100%;
      position: absolute;
      z-index: 9;
      height: 30px;
      background-image: -moz-linear-gradient(top, transparent, black);
      background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, transparent), color-stop(1, #fff));
      z-index: 1;
    }
    .icon {
      display: block;
      text-align: center;
      margin: auto;
      fill: var(--gray-2);
      --size: 12px
    }
  }
  &-figure {
    img {
      margin: 0 auto;
      max-width: 100%;
    }
    &:not(.active) {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &.active {
      img {
        max-width: 24em;
      }
    }
  }

  .differentials {
    margin: 20px 0;
    .icon {
      fill: var(--primary);
      margin-right: 10px;
      --size: 13px;
    }
    &__list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
      grid-column-gap: 5px;
      max-width: 100%;
      grid-row-gap: 25px;
    }
    &__item {
      color: var(--gray-2);
      font-size: 14px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
       strong {
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 500;
       }
    }
  }

}

.maps__tags {
  width: 100%;
  position: relative;
  &-list {
    width: 100%;
    white-space: nowrap;
    overflow: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &-description {
    font-size: 12px;
    color: var(--gray-1);
  }
  &-item {
    font-size: 12px;
    border: 1px solid var(--primary-dark);
    color: var(--primary-dark);
    border-radius: 3px;
    padding: 5px 20px;
    display: inline-flex;
    align-items: center;
    margin: 10px 0;
    font-weight: 600;
  }
  &-button {
    background-color: transparent;
    border: 0;
    position: absolute;
    top: 10px;
    bottom: 0;
    margin: auto;
    outline: none;
    @include for-size(tablet-portrait-down) {
      display: none;
    }

    &--right {
      right: -3em;
    }
    &--left {
      left: -3em;
      .icon {
        transform: rotate(180deg);
      }
    }
  }
}

#map {
  margin: 2em 0;
  width: 100%;
  iframe {
    width: inherit;
  }
}

.neighborhood {
  padding: 3em 0;
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;

    @include for-size(tablet-portrait-down) {
      grid-template-columns: 1fr;
    }
  }
  &__image {
    text-align: center;
    img {
      width: 400px;
      margin: 0 auto;
      @include for-size(phone-only) {
        width: 330px;
      }
    }
  }

  .heading__description {
    font-size: 14px;
    letter-spacing: 0.75px;
    color: #4E4B66;
    margin-top: 2em;
    line-height: 28px;
  }
}

.contact--single__enterprise{
  padding: 2em 0;
  width: 100%;
  background-color: var(--primary-color-dark);
  .form-control__label {
    width: 100%;
    margin: 10px 0;
    color: white;
    font-weight: 400;
  }
  .heading__title {
    text-align: center;
    width: 100%;
    color: white;
    border-bottom: 0;
    position: relative;
    margin-bottom: 2em;
    @include for-size(tablet-portrait-down) {
      --font-size: 1.2em;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: -15px;
      height: 1px;
      width: 310px;
      background-color: #b7b7b7;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
.building-navigation {
  border-bottom: 2px solid var(--gray-3);
  position: relative;
  background: #fff;
  z-index: 1;
  width: 100%;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 1em 0;
  position: sticky;
  top: 0;
  .container {
    display: flex;
    justify-content: space-between;
  }
  &::-webkit-scrollbar {
    display: none;
  }

  &__list {
    display: inline-block;
    text-align: center;
  }
  &__item {
    padding: 10px 20px;
    color: #858585;
    font-size: 20px;
    font-weight: 300;
    display: block;
    &.active {
      border-bottom: 3px solid var(--primary);
      color: var(--primary);
      font-weight: 600;
    }
  }
}






