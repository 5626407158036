.form-control {
  padding: 15px 10px;
  border: 0;
  border-radius: 4px;
  width: 100%;
  border: 1px solid transparent;

  &:focus {
    outline: none;
    border: 1px solid var(--primary-color);

    &::placeholder {
      color: white;
    }
  }

  &::placeholder {
    font-size: 11px;
  }
}

.form-control__message {
  padding: 15px 10px;
  border: 0px;
  border-radius: 4px;
  width: 100%;
  max-width: 467px;
  margin: 0px;
  height: 86px;
}


.js-files.form-control {
  width: 100%;
  height: 50px;
  border-radius: 2px;
  border: none;
  margin-bottom: 15px;
  padding: 10px 20px;
}


