.gallery {
  padding: 2em 0;
  margin-top: 3em;

  .heading{
    text-align: center;
    &__title {
      font-weight: bold;
    }
    &__description {
      color: var(--gray-2);
      font-size: 16px;
    }
  }

  &__grid {
    width: 100%;
    margin-top: 2em;
    position: relative;
    &:before,
    &:after {
      content: "";
      top: 0;
      bottom: 0;
      margin: auto;
      width: 5em;
      height: 100%;
      background-color: #ffffffd6;
      position: absolute;
      z-index: 99;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }

    img {
      max-width: 100%;
      height: 28em;
      object-fit: cover;
    }
  }
  &-title {
    margin-bottom: 4em;
    font-weight: 400;
    letter-spacing: 1px;
    font-size: 22px;
  }
  &-container{
    width: 90%;
    margin-top: 2em;
    margin-bottom: 0em;
    @include for-size(tablet-portrait-down){
      flex-wrap: wrap;
    }
  }
  .section-title {
    width: 100%;
    margin-bottom: 1em;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.6em;
    margin-top: 0;
  }
  &-indice {
    width: 100%;
    display: flex;
    justify-content: center;
    @include for-size(tablet-portrait-down) {
      white-space: nowrap;
      overflow-x: auto;
      padding: 15px;
    }
    &__link {
      padding: 5px 15px;
      border-radius: 5px;
      margin: 5px;
      border: 1px solid transparent;
      color: var(--gray-2);
      &.active {
        color: var(--primary);
        border: 1px solid var(--primary);
      }
    }
  }

}

.gallery--all {
  // background-color: rgb(247, 247, 252);
  .slick-slide {
    margin: 0 20px;
  }
  .slick-slider {
    display: none;
    transition: all 1.2s;
    width: 100%;
    &.active {
      display: block;
    }
  }

}
.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}


.gallery__plantas {
  width: 100%;
  padding: 2em 0;
  .heading__title {
    text-align: center;
  }
}
.gallery__plantas-list {
  width: 100%;
  margin: 3em auto;
  position: relative;
  outline: none;
  &:before,
  &:after {
    content: "";
    top: 0;
    bottom: 0;
    margin: auto;
    width: 13em;
    height: 100%;
    background-color: #ffffffd6;
    position: absolute;
    z-index: 99;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
  .slick-slide {
    margin: 0 20px;
  }
  .slick-arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    svg {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    &.prev {
      left: -30px;
    }
    &.next {
      right: 0;
    }
  }

  img {
    max-width: 430px;
    margin: 0 auto;
  }
}
.slick-slide {
  outline: 0;
}





