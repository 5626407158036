.footer {
  background-color: var(--primary-dark);
  color: white;

  &__column {
    @include for-size(tablet-portrait-down) {
      padding: 20px;
    }
    @include for-size(tablet-portrait-up) {
      &:last-child {
        grid-column-end: none;
      }
    }

    &.first {
      text-align: center;
      .footer__column-title {
        text-align: center;
        font-size: 13px;
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }

    &-title {
      color: var(--primary-light);
      font-weight: 500;
      margin-bottom: 25px;
    }

    &-item {
      font-size: 14px;
      line-height: 160%;
      padding: 10px 0;
      display: block;

      a {
        display: inline-flex;
        align-items: center;

        &:hover {
          text-decoration: underline;
        }
      }

    }

    &-content {
      font-size: 14px;
      line-height: 160%;
      color:var(--gray-6);
    }
    &-logo {
      text-align: center;
      img {
        width: 12em;
        filter: brightness(0) invert(1);
      }
    }
    .icon {
      --size: 25px;
      fill: white;
      margin-right: 10px;
    }

  }

  .container {
    display: grid;
    column-gap: 80px;
    grid-template-columns: repeat(auto-fit, minmax(var(--width, 120px), 1fr));
    width: 100%;
    padding: 4em 0 2em;
    @include for-size(tablet-portrait-down) {
      grid-template-columns: 1fr;
    }
  }
  .copyright {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 2em;
    color: #D9DBE1;
    font-size: 13px;
  }
}
