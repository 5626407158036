.header {
  border-bottom: 1px solid #f2f2f2;

  @include for-size(tablet-portrait-down) {
    border-top: 4px solid var(--primary-dark);
  }

  .container {
    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;
    width: 100%;
  }

  .logo {
    margin-right: 15px;
    margin-top: 10px;
    margin-bottom: 10px;

    img {
      max-width: 110px;
    }
  }
}

