.block {
  padding: 5em 0;
  .container {
    display: grid;
    column-gap: 20px;
    grid-template-columns: 1fr 1fr;

    @include for-size(tablet-portrait-down) {
      grid-template-columns: 1fr;
    }
  }

  .heading__title {
    font-weight: 900;
    color: var(--gray-1);
    line-height: 60px;
  }
  &__list {
    margin-bottom: 2em;
    &-item {
      color: var(--gray-2);
      font-size: 16px;
      line-height: 28px;
      padding: 5px 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      span {
        margin-left: 10px;
      }
    }
  }
  &__image{
    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.block__contact {
  background-color: #F1F3F5;

  &-wrapper {
    width: 100%;
    margin: 4em auto;
    background-color: white;
    max-width: 900px;
    @include for-size(tablet-portrait-up) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .contact__form {
      padding: 0 3em;
      max-width: auto;
    }

    .contact__image {
      height: 100%;
      figure {
        height: 100%;
      }
      img {
        max-width: 100%;
        height: auto;
        @include for-size(tablet-portrait-up) {
          width: 460px;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    form {
      padding: 3.5em 4em;
      @include for-size(tablet-portrait-down) {
        padding: 20px;
      }
    }

    .btn-link {
      color: var(--primary);
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      font-size: 15px;
      margin: 20px 0;

      .icon {
        --size: 18px;
        margin-left: 10px;
      }
    }
  }
  .input-group {
    margin-bottom: 1em;
    position: relative;
    .icon{
      position: absolute;
      top: 0;
      right: 15px;
      margin: auto;
      bottom: 0;
    }

  }

  .heading__title {
    font-weight: 600;
    color: var(--gray-1);
    margin-bottom: 1em;
  }
  .form-control {
    border: 1px solid var(--gray-4);
    border-radius: 0;
    height: 55px;
    

    &::-webkit-input-placeholder { /* Edge */
      color: var(--gray-2);
      font-size: 15px;
      font-weight: 200;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: var(--gray-2);
      font-size: 15px;
      font-weight: 200;
    }

    &::placeholder {
      color: var(--gray-2);
      font-size: 15px;
      font-weight: 200;
    }

    &:focus::-webkit-input-placeholder,
    &:focus::placeholder {
      opacity: 0;
    }

  }

  .form-control__label{
    width: 100%;
    display: block;
    color: var(--gray-3);
    font-weight: 300;
    font-size: 14px;
    padding-left: 3px;
  }

  .btn {
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}
