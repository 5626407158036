.obras {
  padding: 3em 0;
  &__content {
    width: 100%;
    @include for-size(tablet-portrait-up) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 30px;
    }
  }

  &__gallery{
    &-wrapper {
      max-width: 35em;
      padding: 0 2em;
    }
    img {
      max-width: 100%;
      height: 30em;
    }
    .slick-arrow {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      z-index: 99;
      svg {
        top: 0;
        bottom: 0;
        position: absolute;
        margin: auto;
        fill: white;
      }
    }
    .prev {
      left: 10px;
    }
    .next {
      right: 10px;
      svg {
        right: 0;
      }
    }
  }

  .heading__title  {
    font-weight: 800;
    margin-bottom: 1em;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }


}
//  svg anmation
.svg-item {
  width: 100px;
  height: 100px;
  font-size: 16px;
}
.donut {
  width: 100px;
  height: 100px;
}
.donut-ring {
  stroke: #EBEBEB;
}

.donut-segment {
  animation: donut-chart-fill 1s reverse ease-in;
  transform-origin: center;
  stroke: #00966D;
}

.donut-text {
  fill: #00966D;
}

.donut-label {
  font-size: 0.28em;
  font-weight: 700;
  line-height: 1;
  fill: #000;
  transform: translateY(0.25em);
}

.donut-percent {
  font-size: 0.5em;
  fill: #00966D;
  line-height: 1;
  transform: translateY(0.5em);
}



