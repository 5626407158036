.internal__page {
  background-size: contain;
  background-repeat: no-repeat;
  .heading__description {
    padding: 2em 0;
    max-width: 40em;
    color: var(--gray-2);
    @include for-size(tablet-portrait-down) {
      padding: 1em;
    }
  }
  &--assistencia {
    background-image: url('/decorate-2.png');
    background-size: 33em;
    background-position: top 2em right;
    background-repeat: no-repeat;

    @include for-size(phone-only) {
      background-position: top 0 right;
      background-size: 20em;
    }

    .heading__description {
      @include for-size(tablet-portrait-down) {
        background-color: white;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        padding: 20px;
        margin-top: 12em;
      }
    }

  }

  &--aprovacao {
    padding: 3em;
    text-align: center;
    .heading__title{
      border: 0;
    }
    .heading__description {
      text-align: center;
      margin: auto;
    }
  }

  &-column {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    position: relative;
    justify-content: center;
    @include for-size(tablet-portrait-down) {
      margin-top: 2em;
    }
    &--figure {
      content: '';
      width: 100%;
      background-size: 100%;
      background-repeat: no-repeat;
    }
  }
  &-content {
    width: 100%;
    @include for-size(desktop-up) {
      grid-gap: 30px;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  .contact {
    padding: 1em 2em;
    @include for-size(tablet-portrait-down) {
      margin: auto;
      padding: 1em;
    }
    @include for-size(desktop-up) {
      margin: 0;
    }

    .checkbox-type-contact {
     --gutter: 10px;
      margin: 5px;
    }
  }
  .internal__page-info {
    width: 500px;
    border-radius: 5px;
    margin: 0 auto;
    &-header{
      background-color: var(--primary-dark);
      color: white;
      padding: 1em 2em;
    }
    &-content {
      padding: 1em 2em;
      background-color: #F7F7FC;
    }
    &-list {
      color: #4E4B66;
      padding: 10px 5px;
      font-size: 14px;
      .icon {
        --size: 14px;
        margin-right: 5px;
        fill: #4E4B66;
      }
    }
  }


}
.internal__page-social {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: 3em;
  &-header {
    width: 100%;
    color: var(--primary-dark);
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-weight: 700;
  }

  .icon {
   --size: 35px
  }
}

.form-recovery {
  background-color: #F7F7FC;
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  border: 1px solid #ddd;
  width: 400px;
  max-width: 100%;
  margin: 2em auto;
  padding: 40px;
  .form-control__label {
    color: #6b6b6b;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .checkbox-type-contact {
    width: 100% ;
  }
  .btn {
    margin: 20px auto;
  }

}


.maps {
  width: 100%;
  margin-top: 3em;
}
