.banner {
  background-color: #000;
  background-size: cover;
  padding: 4em 0;

  .container {
    align-content: center;
    height: 100%;
  }
  &__content {
    color: white;
    align-items: center;
    width: clamp(25em, 40%, 40em);

  }
  &__title {
    font-size: max(2.8em, 2.5vw);
    color: var(--primary-dark);
    margin: 0;
  }

  &__description {
    color: var(--gray-2);
    font-size: max(1em, 16px);
    line-height: 28px;
  }

  &__btn {
    --font-size: 14px;
    font-weight: 400;
  }
}

.banner-2 {
  padding: 5em 0 9em;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;
}

.banner--quem-somos {
  padding: 5em 0;
  .banner__content {
    width: clamp(200px, 80% + 21px, 560px);
  }
  .banner__description,
  .banner__title {
    color: white;
  }
  .banner__title {
    font-weight: 600;
    position: relative;
    &:before {
      content: "";
      top: -0.7em;
      left: -20px;
      right: 0;
      border: 3px solid white;
      width: clamp(220px, 110% + 21px, 760px);
      position: absolute;
      height: 2em;
    }
  }
  .banner__description {
    font-weight: 300;
    margin-top: 20px;
  }

}

.banner--results {
  .banner__content {
    padding: 0 12em;
    width: 100%;
    text-align: center;
  }
  .banner__title {
    font-weight: 800;
  }
  .search {
    padding: 10px 20px;
  }
}
