.mini-banner {
  padding: 10em 0;
  background-size: cover;
  text-align: center;
  .heading__title {
    max-width: 15em;
    color: white;
    text-align: center;
    font-size: max(2.2em, 2.6vw);
    margin: 0 auto;
    line-height: 140%;
    letter-spacing: 0.16em;
    text-transform: uppercase;
  }
}

.disclaimer {
  padding: 3em 0;
  text-align: center;
  .heading__title {
    font-weight: 400;
    padding: 1em 2em;
    line-height: 130%;

    @include for-size(tablet-portrait-down) {
      font-size: 27px;
      padding: 0;
    }
  }

  &__description {
    color: var(--gray-2);
    font-size: 18px;
    line-height: 28px;
  }

  &__big-text {
    font-size: 5.5em;
    font-weight: 600;
    color: var(--gray-1);
    line-height: 130%;
    margin-bottom: 20px;
  }

 .block__list {
    justify-content: center;
    max-width: 550px;
    margin: 0 auto;
    &-item {
      display: inline-flex;
      margin: 0 10px;
    }
  }

}

.quem__somos-content {
  color: var(--gray-2);
  font-size: 16px;
  line-height: 28px;
  padding: 1em 10em;

  @include for-size(tablet-portrait-down) {
    padding: 0;
  }
}

.quem__somos-image {
  width: 100%;
  text-align: center;
  margin: 2em 0;
  img {
    height: auto;
  }
}
.btn-disclaimer {
  display: block;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.9em;
  text-transform: inherit;
  padding: 1em 16px;
  width: 100%;
  text-align: center;
  background: var(--primary-dark);
  margin-bottom: 2em;

  svg {
    display: inline-block;
    vertical-align: middle;
  }
}
