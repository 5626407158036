.section {
  margin: 3em 0;
}

.section__details {
  margin-top: 3em;
  background-color: var(--gray-5);
  padding: 5em 0;

  .container {
    display: flex;
    flex-wrap: wrap;
  }

  &-column {
    width: calc(100% / var(--items, 2) );
    justify-content: center;
    text-align: center;

    @include for-size(tablet-portrait-down) {
      --items: 1;
    }

    .heading__title {
      --color: var(--gray-1);
      font-size: clamp(2.3em, 3vw, 3.4em);
      max-width: 17em;
      font-weight: bold;
      margin-bottom: 30px;
      text-align: left;
      @include for-size(tablet-portrait-up) {
        padding: 0 2.5em;
      }
    }
  }
  &-image {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  &-row {
    display: flex;
    flex-wrap: wrap;
  }

  &-content {
    padding: 2em ​0;
    position: relative;
    background-color: var(--gray-5);
    margin-top: 5em;

    &:before{
      content: "";
      border: 2px solid var(--gray-1);
      width: 30em;
      height: 37em;
      position: absolute;
      top: -45%;
      bottom: 20%;
      margin: 0;
      left: -50%;
      z-index: 2;
      @include for-size(tablet-landscape-up) {
        right: 50%;
      }
      @include for-size(tablet-portrait-down) {
        width: 95%;
        height: 35em;
        top: -25%;
        left: 1em;
        z-index: 2;
      }
    }
  }
  &-description {
    padding: 5em 0px;
    color: var(--gray-2);
    line-height: 28px;
    font-size: clamp(1em, 1vw, 1.2em);
    z-index: 3;
    position: relative;
    background: linear-gradient(180deg, rgba(241, 243, 245, 0.05) 0%, #F1F3F5 15.1%, #F1F3F5 83.85%, rgba(241, 243, 245, 0.05) 100%);
    width: clamp(300px, 60% + 21px, 400px);
    text-align: left;
  }

  &--order {
    margin-top: 5em;
    @include for-size(tablet-portrait-down) {
      margin-top: 0;
      order: 3;
    }
  }

}

.section__property-list {

  .heading {
    width: 100%;
    margin-bottom: 2em;
  }
  .heading__description {
    color: var(--gray-2);
    padding-top: 10px;
    font-size: max(1.1em, 1.3vw);
    margin: 0;
    font-weight: 300;
    font-size: 17px;
  }
  .btn {
    align-items:center;
    display: inline-flex;
    .icon {
      fill: white;
      --size: 14px;
      margin-right: 5px;
      margin-top: -2px;
    }
  }
}

.section__enterprise-list {
  // background-color: #14142B;
  padding: 2em 0;
  padding-bottom: 0;
  .heading {
    width: 100%;
    margin-bottom: 2em;
    display: grid;
    align-items: center;
    // color: white;
    color: #4E4B66;
    grid-template-columns: 1fr 1fr;
    position: relative;
    @include for-size(tablet-portrait-down) {
      grid-template-columns: 1fr;
      .heading__title {
        text-align: left;
      }
    }
  }
  .heading__title {
    text-align: right;
    margin-right: 50px;
    color: #4E4B66;
    font-weight: 300;
    --border: 0;
    b {
      color: var(--primary-color);
    }
  }
  .heading__description {
    color: #4E4B66;
    padding-top: 10px;
    max-width: 24em;
    border: 0;

    &:before {
      content: '';
      width: 60%;
      height: 1px;
      bottom: -20px;
      position: absolute;
      background-color: #EFF0F6;
      right: auto;
      @include for-size(tablet-portrait-up) {
        right: -60%;
        margin-right: 50%;
      }
    }
    strong {
      color: var(--primary-color);
    }
  }
}
.section__blog-list {
  margin: 4em 0;
}

.maps-heading {
  width: 100%;
  .heading__title {
    padding: 10px 0;
  }
  .heading__description {
    font-size: 14px;
    color: var(--gray-3);
  }
}

.carrousel-building {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;

  .slick-arrow {
    top: 0;
    bottom: 0;
    margin: auto;
    position:absolute;
    height: 60px;
    &.prev {
      left: -50px;
    }
    &.next {
      right: -50%;
    }
  }
}




