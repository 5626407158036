.btn {
  padding: var(--padding, 17px 20px);
  border: var(--btn-border, 0px solid transparant);
  background: var(--primary);
  display: inline-flex;
  color: var(--btn-color, #FFF);
  font-size: var(--font-size, 14px);
  text-transform: var(--transform, uppercase);
  align-items: center;
  cursor: pointer;
  line-height: 24px;
  font-weight: 300;
  transition: ease 0.2s;
  &:hover {
    background: var(--primary-dark);
  }
  .icon {
    fill: var(--fill, white);
    --size: 15px;
    margin-right: 5px;
  }
}

.btn-link {
  display: block;
  margin: 10px;
  font-size: 11px;
  text-decoration: underline;
}
