.contact {
  padding: 2em 0;
  display: flex;
  background-color: #F7F7FC;
  .checkbox-type-contact {
    --gutter: 20px;
    width: calc(100% / var(--items, 3) - var(--gutter));
  }
  .form-control__label {
    width: 100%;
    color: #4E4B66;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 10px;
  }
  &__form {
    width: 100%;
    max-width: 460px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    color: #FFF;
    margin: 0 auto;
  }
  .btn {
    width: 100%;
    text-align: center;
    --btn-background: #00BA88;
    --btn-color: white;
    --padding: 15px 20px;
    margin: 20px 10px;
    justify-content: center;
    font-size: 15px;
    letter-spacing: 0.75px;
    margin: 10px 0;
    &:hover {
      --btn-background: #007757
    }
  }
  .input-group {
    margin: 8px 0;
    position: relative;

    .icon {
      position: absolute;
      top: 0;
      right: 15px;
      margin: auto;
      bottom: 0;
    }

    &--message {
      .icon {
        bottom: auto;
        top: 20px;
      }
    }
  }
}

.checkbox-type-contact {
  margin: 0 10px;
  width: 150px;
  border-radius: 6px;
  color: #A0A3BD;
  text-align: center;
  font-size: 13px;

  label {
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: block;
  }
  input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  .icon {
    fill: #A0A3BD;
    --size: 42px;
    margin-bottom: 10px;
    @include for-size(tablet-portrait-down) {
      --size: 30px;
      margin-bottom: 0;
    }
  }
}

.input-name  {
  width: 100%;
  display: block;
  margin: 5px 0px;
  padding: 10px;
  background-color: white;
  transition: ease-in 0.2s;
  @include for-size(tablet-portrait-down){
    margin: 0;
  }
}
.checkbox-type-contact label input:checked ~ .input-name {
  font-weight: 600;
  background-color: var(--primary);
  color: white;
}
@keyframes input-check {
  0% {
    transform: scale(0) rotate(-45deg);
  }
  100% {
    transform: scale(1) rotate(-45deg);
  }
}


.internal__page-column .contact{
  margin: 2em;
}
