.cards {
  width: calc(100% / var(--items, 3) - 20px);
  margin: 20px 10px;
  position: relative;
  @include for-size(tablet-portrait-down) {
    --items: 1;
  }
  &__image {
    margin: 0;
    max-height: 510px;
    overflow: hidden;
    height: 510px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__content {
    padding: 20px;
    color: var(--color, #000);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &-heading {
      width: 100%;
    }
  }
  &__title {
    font-size: max(1.6em, 1.9vw);
    color: var(--gray-1);
    line-height: 32px;
    margin-bottom: 10px;
  }
  &__description {
    line-height: 18px;
    color: var(--gray-2);
    font-size: max(1em, 1vw);
    margin: 5px 0;
    font-weight: 600;
    margin-bottom: 20px;
  }
  &__features {
    color: var(--gray-2);
    line-height: 24px;
    font-size: clamp(14px, 1vw, 1.5em);
    margin-top: 10px;
    font-weight: 400;
    &-item {
      margin-bottom: 10px;
      display: inline-flex;
      align-items: center;
      width: 100%;
      .icon {
        --size: 20px;
        margin-right: 10px;
      }
    }
  }

  &__flag {
    border-radius: 100px;
    background: var(--primary-light);
    color: var(--primary);
    text-align: center;
    padding: 6px 13px;
    font-size: 12px;
    margin-bottom: 20px;
    display: inline-flex;
    align-items: center;

    .icon {
      --size: 16px;
      margin-right: 10px;
    }
  }
}

