@charset "UTF-8";
/* Calculates maximum width for post content */
* {
  box-sizing: border-box;
}

:root {
  --primary: #0CAAE4;
  --primary-dark: #065A79;
  --primary-light: #CFF0FC;
  --gray-1: #212529;
  --gray-2: #74808B;
  --gray-3: #ADB5BD;
  --gray-4: #DEE2E6;
  --gray-5: #F1F3F5;
  --gray-6: #F8F9FA;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  transition: transform 0.4s cubic-bezier(0.75, 0, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.75, 0, 0.25, 1);
  overflow-x: hidden;
  font-family: "Roboto", sans-serif;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0.75px;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}

ul {
  padding-left: 0;
}

h1,
h2,
h3 {
  margin: 0;
}

figure {
  margin: 0;
}

.display-flex, .container {
  display: flex;
  flex-wrap: wrap;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1300px;
  }
}

.icon {
  --size: 25px;
  width: var(--size);
  height: var(--size);
}

.overlay-menu {
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.768627451);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 55;
}

.show-menu .overlay-menu {
  display: block;
}

.heading {
  width: 100%;
}
.heading__title {
  color: var(--color, var(--primary-dark));
  font-size: max(2.6em, 2.5vw);
}

.overlay {
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.768627451);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 55;
}

#login {
  display: none;
}

#signup {
  display: none;
}

#login.active {
  display: block;
}

#signup.active {
  display: block;
}

.show-password {
  position: relative;
}
.show-password .form-control {
  margin-bottom: 0 !important;
}

.show-password__btn {
  position: absolute;
  right: 10px;
  top: 5px;
  bottom: 0;
  margin: auto;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.show-password__btn .icon {
  fill: #636161;
}
.show-password__btn.active .icon--aberto {
  display: none;
}
.show-password__btn.active .icon--fechado {
  display: block;
}
.show-password__btn .icon--aberto {
  display: block;
}
.show-password__btn .icon--fechado {
  display: none;
}

.lightbox {
  overflow-y: scroll;
  height: 100%;
}

.renove-utm {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  background-color: var(--primary);
}

@-webkit-keyframes animate-svg-fill {
  0% {
    fill: transparent;
  }
  100% {
    fill: white;
  }
}
@keyframes animate-svg-fill {
  0% {
    fill: transparent;
  }
  100% {
    fill: white;
  }
}
.svg-elem-1 {
  -webkit-animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 0.8s both;
  animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 0.8s both;
}

.svg-elem-2 {
  -webkit-animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 0.9s both;
  animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 0.9s both;
}

.svg-elem-3 {
  -webkit-animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
  animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1s both;
}

.svg-elem-4 {
  -webkit-animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.1s both;
  animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.1s both;
}

.svg-elem-5 {
  -webkit-animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.2s both;
  animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.2s both;
}

.svg-elem-6 {
  -webkit-animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.3s both;
  animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.3s both;
}

.svg-elem-7 {
  -webkit-animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.4s both;
  animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.4s both;
}

.svg-elem-8 {
  -webkit-animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.5s both;
  animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.5s both;
}

.svg-elem-9 {
  -webkit-animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.6s both;
  animation: animate-svg-fill 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) 1.6s both;
}

.btn {
  padding: var(--padding, 17px 20px);
  border: var(--btn-border, 0px solid transparant);
  background: var(--primary);
  display: inline-flex;
  color: var(--btn-color, #FFF);
  font-size: var(--font-size, 14px);
  text-transform: var(--transform, uppercase);
  align-items: center;
  cursor: pointer;
  line-height: 24px;
  font-weight: 300;
  transition: ease 0.2s;
}
.btn:hover {
  background: var(--primary-dark);
}
.btn .icon {
  fill: var(--fill, white);
  --size: 15px;
  margin-right: 5px;
}

.btn-link {
  display: block;
  margin: 10px;
  font-size: 11px;
  text-decoration: underline;
}

.search {
  display: flex;
  flex-wrap: wrap;
  width: var(--width, 100%);
  padding: 20px;
}
.search form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  background-color: white;
}
.search .input-group {
  --width: 100%;
  border-right: 2px solid #f1f3f5;
}
@media (min-width: 600px) {
  .search .input-group {
    --width: 300px;
  }
}
.search .btn {
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search .btn .icon {
  margin-left: 15px;
}

@media (max-width: 768px) {
  .input-group__btn-wrapper {
    width: 100%;
  }
}

.progress {
  display: flex;
  height: 4px;
  font-size: 0.75rem;
  background-color: var(--gray-3);
}

.progress__bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: var(--gray-2);
  transition: width 0.6s ease;
  position: relative;
}
.progress__bar:before {
  content: "";
  top: 0;
  bottom: 0;
  margin: auto;
  right: -5px;
  width: 15px;
  height: 15px;
  border-radius: 100px;
  background-color: var(--gray-2);
  position: absolute;
  z-index: 99;
  display: block;
}

.input-group {
  border: 0;
  margin: 0;
  padding: 0;
  width: var(--width, 100%);
  display: block;
}
.input-group--variation {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.input-group label {
  width: 100%;
  display: block;
  color: var(--color, #4E4B66);
  font-weight: 600;
  font-size: 14px;
  padding-left: 3px;
}

.dropdown__price-group {
  display: flex;
  flex-wrap: wrap;
}
.dropdown__price-group-label {
  position: relative;
  cursor: text;
  display: flex;
  height: 55px;
  width: 100%;
  margin: 10px 15px;
  color: #222222;
  background-color: white;
  border-radius: 8px;
  box-shadow: #b0b0b0 0px 0px 0px 1px inset;
  font-size: 16px;
  line-height: 20px;
}
.dropdown__price-group-value {
  display: flex;
  opacity: 1;
  color: var(--cinza);
  font-weight: 300;
}
.dropdown__price-group-legend {
  position: absolute;
  top: 18px;
  left: 12px;
  right: 12px;
  margin: 0px;
  padding: 0px;
  color: #717171;
  pointer-events: none;
  transform-origin: 0% 0%;
  font-family: Circular, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 20px;
  transition: -ms-transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, -webkit-transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s, transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
  transform: translateY(-8px) scale(0.75);
  font-weight: 400;
}
.dropdown__price-group-symbol {
  padding-top: 28px;
  padding-left: 9px;
}
.dropdown__price-group-form-control {
  width: 100%;
  border: none;
  outline: none;
  padding: 0px;
  margin: 28px 5px 10px;
  min-height: 1px;
  color: inherit;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  appearance: none;
}

.switch__container {
  margin: 15px auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.switch {
  visibility: hidden;
  position: absolute;
  margin-left: -9999px;
}

.switch__title {
  font-size: 15px;
}

.switch + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

.switch--shadow + label {
  padding: 2px;
  width: 51px;
  height: 30px;
  background-color: white;
  border-radius: 60px;
  margin: 0 10px;
}

.switch--shadow + label:before,
.switch--shadow + label:after {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  content: "";
}

.switch--shadow + label:before {
  right: 0;
  background-color: #f1f1f1;
  border-radius: 60px;
  transition: background 0.4s;
}

.switch--shadow + label:after {
  width: 25px;
  height: 25px;
  top: 2px;
  left: 5px;
  background-color: #D8443C;
  border-radius: 100%;
  transition: all 0.4s;
}

.switch--shadow:checked + label:before {
  background-color: var(--primary-color-dark);
}

.switch--shadow:checked + label:after {
  transform: translateX(19px);
  background-color: white;
}

.pills__group {
  display: flex;
  align-items: center;
  margin: 8px 0;
  border-radius: 8px;
  overflow: hidden;
  height: 45px;
  align-items: center;
  border: 0;
  background-color: #fff;
}
.pills__group-item {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.pills__group-item:before {
  content: " ";
  height: 20px;
  width: 1px;
  background-color: #d8e2e4;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 0;
}
.pills__group-item button {
  background-color: inherit;
  color: #A0A3BD;
  width: 100%;
  height: 100%;
  outline: none;
  font-size: 15px;
  font-weight: 400;
  border: 0;
}

.radio-custom-group {
  width: 270px;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
}

.radio-custom {
  font-size: 14px;
  display: block;
  font-weight: 200;
  padding: 20px 2em;
  text-align: center;
  text-transform: initial;
  width: 50%;
  padding: 10px;
  transition: background 0.2s, color 0.2s;
  background-color: white;
  color: #A0A3BD;
}
.radio-custom:first-of-type {
  margin-left: 0;
  position: relative;
}
.radio-custom:first-of-type:before {
  content: "";
  width: 1px;
  height: 21px;
  background-color: #e4e4e4;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.radio-custom:hover {
  cursor: pointer;
}
.radio-custom label {
  color: var(--cinza);
}

input[type=radio] {
  display: none;
}
input[type=radio]:checked + label {
  background: var(--primary-color);
  color: #fff;
}

.select-control {
  border: 0px;
  padding: 5px 0px;
  color: #86889a;
  width: 100%;
  letter-spacing: 0.75px;
  font-size: 12px;
}

.form-control {
  padding: 15px 10px;
  border: 0;
  border-radius: 4px;
  width: 100%;
  border: 1px solid transparent;
}
.form-control:focus {
  outline: none;
  border: 1px solid var(--primary-color);
}
.form-control:focus::placeholder {
  color: white;
}
.form-control::placeholder {
  font-size: 11px;
}

.form-control__message {
  padding: 15px 10px;
  border: 0px;
  border-radius: 4px;
  width: 100%;
  max-width: 467px;
  margin: 0px;
  height: 86px;
}

.js-files.form-control {
  width: 100%;
  height: 50px;
  border-radius: 2px;
  border: none;
  margin-bottom: 15px;
  padding: 10px 20px;
}

.dropdown__select {
  width: 100%;
  position: relative;
}
.dropdown__select.active .dropdown__select-list {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
  z-index: 99;
  border-radius: 4px;
}
.dropdown__select .selLabel {
  width: 100%;
  height: 50px;
  line-height: 50px;
  color: var(--gray-2);
  font-size: 14px;
  background: white;
  padding: 0 15px;
  margin-top: 5px;
  position: relative;
  z-index: 50;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-weight: 400;
  justify-content: space-between;
}
.dropdown__select .selLabel .icon {
  --size: 12px;
  margin-left: 10px;
}
.dropdown__select .selLabel.active .selLabel:after {
  transform: rotate(0deg);
}
.dropdown__select-item {
  display: block;
  list-style: none;
  left: 0;
  opacity: 1;
  transition: transform 300ms ease;
  position: relative;
  top: 0;
  width: 100%;
}
.dropdown__select-list {
  background-color: white;
  position: absolute;
  top: auto;
  width: 100%;
  margin-top: 0;
  box-shadow: 1px 0px 7px -1px rgba(0, 0, 0, 0.2);
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  z-index: 99;
  font-size: 14px;
  padding: 15px 0;
}
.dropdown__select-list li {
  padding: 10px 20px;
  color: var(--cinza);
  cursor: pointer;
}
.dropdown__select-list li:hover {
  background-color: #DDD;
}

.obras {
  padding: 3em 0;
}
.obras__content {
  width: 100%;
}
@media (min-width: 600px) {
  .obras__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
  }
}
.obras__gallery-wrapper {
  max-width: 35em;
  padding: 0 2em;
}
.obras__gallery img {
  max-width: 100%;
  height: 30em;
}
.obras__gallery .slick-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 99;
}
.obras__gallery .slick-arrow svg {
  top: 0;
  bottom: 0;
  position: absolute;
  margin: auto;
  fill: white;
}
.obras__gallery .prev {
  left: 10px;
}
.obras__gallery .next {
  right: 10px;
}
.obras__gallery .next svg {
  right: 0;
}
.obras .heading__title {
  font-weight: 800;
  margin-bottom: 1em;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}

.svg-item {
  width: 100px;
  height: 100px;
  font-size: 16px;
}

.donut {
  width: 100px;
  height: 100px;
}

.donut-ring {
  stroke: #EBEBEB;
}

.donut-segment {
  animation: donut-chart-fill 1s reverse ease-in;
  transform-origin: center;
  stroke: #00966D;
}

.donut-text {
  fill: #00966D;
}

.donut-label {
  font-size: 0.28em;
  font-weight: 700;
  line-height: 1;
  fill: #000;
  transform: translateY(0.25em);
}

.donut-percent {
  font-size: 0.5em;
  fill: #00966D;
  line-height: 1;
  transform: translateY(0.5em);
}

.contact {
  padding: 2em 0;
  display: flex;
  background-color: #F7F7FC;
}
.contact .checkbox-type-contact {
  --gutter: 20px;
  width: calc(100% / var(--items, 3) - var(--gutter));
}
.contact .form-control__label {
  width: 100%;
  color: #4E4B66;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 10px;
}
.contact__form {
  width: 100%;
  max-width: 460px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  color: #FFF;
  margin: 0 auto;
}
.contact .btn {
  width: 100%;
  text-align: center;
  --btn-background: #00BA88;
  --btn-color: white;
  --padding: 15px 20px;
  margin: 20px 10px;
  justify-content: center;
  font-size: 15px;
  letter-spacing: 0.75px;
  margin: 10px 0;
}
.contact .btn:hover {
  --btn-background: #007757 ;
}
.contact .input-group {
  margin: 8px 0;
  position: relative;
}
.contact .input-group .icon {
  position: absolute;
  top: 0;
  right: 15px;
  margin: auto;
  bottom: 0;
}
.contact .input-group--message .icon {
  bottom: auto;
  top: 20px;
}

.checkbox-type-contact {
  margin: 0 10px;
  width: 150px;
  border-radius: 6px;
  color: #A0A3BD;
  text-align: center;
  font-size: 13px;
}
.checkbox-type-contact label {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: block;
}
.checkbox-type-contact input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.checkbox-type-contact .icon {
  fill: #A0A3BD;
  --size: 42px;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .checkbox-type-contact .icon {
    --size: 30px;
    margin-bottom: 0;
  }
}

.input-name {
  width: 100%;
  display: block;
  margin: 5px 0px;
  padding: 10px;
  background-color: white;
  transition: ease-in 0.2s;
}
@media (max-width: 768px) {
  .input-name {
    margin: 0;
  }
}

.checkbox-type-contact label input:checked ~ .input-name {
  font-weight: 600;
  background-color: var(--primary);
  color: white;
}

@keyframes input-check {
  0% {
    transform: scale(0) rotate(-45deg);
  }
  100% {
    transform: scale(1) rotate(-45deg);
  }
}
.internal__page-column .contact {
  margin: 2em;
}

.breadcrumbs {
  font-size: 12px;
  font-weight: 400;
  margin: 10px 0;
}
.breadcrumbs__item--current {
  color: #949494;
}
.breadcrumbs-separator {
  margin: 0 5px;
}

.header {
  border-bottom: 1px solid #f2f2f2;
}
@media (max-width: 768px) {
  .header {
    border-top: 4px solid var(--primary-dark);
  }
}
.header .container {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
  width: 100%;
}
.header .logo {
  margin-right: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.header .logo img {
  max-width: 110px;
}

.banner {
  background-color: #000;
  background-size: cover;
  padding: 4em 0;
}
.banner .container {
  align-content: center;
  height: 100%;
}
.banner__content {
  color: white;
  align-items: center;
  width: clamp(25em, 40%, 40em);
}
.banner__title {
  font-size: max(2.8em, 2.5vw);
  color: var(--primary-dark);
  margin: 0;
}
.banner__description {
  color: var(--gray-2);
  font-size: max(1em, 16px);
  line-height: 28px;
}
.banner__btn {
  --font-size: 14px;
  font-weight: 400;
}

.banner-2 {
  padding: 5em 0 9em;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: white;
}

.banner--quem-somos {
  padding: 5em 0;
}
.banner--quem-somos .banner__content {
  width: clamp(200px, 80% + 21px, 560px);
}
.banner--quem-somos .banner__description,
.banner--quem-somos .banner__title {
  color: white;
}
.banner--quem-somos .banner__title {
  font-weight: 600;
  position: relative;
}
.banner--quem-somos .banner__title:before {
  content: "";
  top: -0.7em;
  left: -20px;
  right: 0;
  border: 3px solid white;
  width: clamp(220px, 110% + 21px, 760px);
  position: absolute;
  height: 2em;
}
.banner--quem-somos .banner__description {
  font-weight: 300;
  margin-top: 20px;
}

.banner--results .banner__content {
  padding: 0 12em;
  width: 100%;
  text-align: center;
}
.banner--results .banner__title {
  font-weight: 800;
}
.banner--results .search {
  padding: 10px 20px;
}

.section {
  margin: 3em 0;
}

.section__details {
  margin-top: 3em;
  background-color: var(--gray-5);
  padding: 5em 0;
}
.section__details .container {
  display: flex;
  flex-wrap: wrap;
}
.section__details-column {
  width: calc(100% / var(--items, 2) );
  justify-content: center;
  text-align: center;
}
@media (max-width: 768px) {
  .section__details-column {
    --items: 1;
  }
}
.section__details-column .heading__title {
  --color: var(--gray-1);
  font-size: clamp(2.3em, 3vw, 3.4em);
  max-width: 17em;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: left;
}
@media (min-width: 600px) {
  .section__details-column .heading__title {
    padding: 0 2.5em;
  }
}
.section__details-image img {
  max-width: 100%;
  height: auto;
}
.section__details-row {
  display: flex;
  flex-wrap: wrap;
}
.section__details-content {
  padding: 2em ​0;
  position: relative;
  background-color: var(--gray-5);
  margin-top: 5em;
}
.section__details-content:before {
  content: "";
  border: 2px solid var(--gray-1);
  width: 30em;
  height: 37em;
  position: absolute;
  top: -45%;
  bottom: 20%;
  margin: 0;
  left: -50%;
  z-index: 2;
}
@media (min-width: 900px) {
  .section__details-content:before {
    right: 50%;
  }
}
@media (max-width: 768px) {
  .section__details-content:before {
    width: 95%;
    height: 35em;
    top: -25%;
    left: 1em;
    z-index: 2;
  }
}
.section__details-description {
  padding: 5em 0px;
  color: var(--gray-2);
  line-height: 28px;
  font-size: clamp(1em, 1vw, 1.2em);
  z-index: 3;
  position: relative;
  background: linear-gradient(180deg, rgba(241, 243, 245, 0.05) 0%, #F1F3F5 15.1%, #F1F3F5 83.85%, rgba(241, 243, 245, 0.05) 100%);
  width: clamp(300px, 60% + 21px, 400px);
  text-align: left;
}
.section__details--order {
  margin-top: 5em;
}
@media (max-width: 768px) {
  .section__details--order {
    margin-top: 0;
    order: 3;
  }
}

.section__property-list .heading {
  width: 100%;
  margin-bottom: 2em;
}
.section__property-list .heading__description {
  color: var(--gray-2);
  padding-top: 10px;
  font-size: max(1.1em, 1.3vw);
  margin: 0;
  font-weight: 300;
  font-size: 17px;
}
.section__property-list .btn {
  align-items: center;
  display: inline-flex;
}
.section__property-list .btn .icon {
  fill: white;
  --size: 14px;
  margin-right: 5px;
  margin-top: -2px;
}

.section__enterprise-list {
  padding: 2em 0;
  padding-bottom: 0;
}
.section__enterprise-list .heading {
  width: 100%;
  margin-bottom: 2em;
  display: grid;
  align-items: center;
  color: #4E4B66;
  grid-template-columns: 1fr 1fr;
  position: relative;
}
@media (max-width: 768px) {
  .section__enterprise-list .heading {
    grid-template-columns: 1fr;
  }
  .section__enterprise-list .heading .heading__title {
    text-align: left;
  }
}
.section__enterprise-list .heading__title {
  text-align: right;
  margin-right: 50px;
  color: #4E4B66;
  font-weight: 300;
  --border: 0;
}
.section__enterprise-list .heading__title b {
  color: var(--primary-color);
}
.section__enterprise-list .heading__description {
  color: #4E4B66;
  padding-top: 10px;
  max-width: 24em;
  border: 0;
}
.section__enterprise-list .heading__description:before {
  content: "";
  width: 60%;
  height: 1px;
  bottom: -20px;
  position: absolute;
  background-color: #EFF0F6;
  right: auto;
}
@media (min-width: 600px) {
  .section__enterprise-list .heading__description:before {
    right: -60%;
    margin-right: 50%;
  }
}
.section__enterprise-list .heading__description strong {
  color: var(--primary-color);
}

.section__blog-list {
  margin: 4em 0;
}

.maps-heading {
  width: 100%;
}
.maps-heading .heading__title {
  padding: 10px 0;
}
.maps-heading .heading__description {
  font-size: 14px;
  color: var(--gray-3);
}

.carrousel-building {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
}
.carrousel-building .slick-arrow {
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  height: 60px;
}
.carrousel-building .slick-arrow.prev {
  left: -50px;
}
.carrousel-building .slick-arrow.next {
  right: -50%;
}

.block {
  padding: 5em 0;
}
.block .container {
  display: grid;
  column-gap: 20px;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 768px) {
  .block .container {
    grid-template-columns: 1fr;
  }
}
.block .heading__title {
  font-weight: 900;
  color: var(--gray-1);
  line-height: 60px;
}
.block__list {
  margin-bottom: 2em;
}
.block__list-item {
  color: var(--gray-2);
  font-size: 16px;
  line-height: 28px;
  padding: 5px 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.block__list-item span {
  margin-left: 10px;
}
.block__image img {
  max-width: 100%;
  height: auto;
}

.block__contact {
  background-color: #F1F3F5;
}
.block__contact-wrapper {
  width: 100%;
  margin: 4em auto;
  background-color: white;
  max-width: 900px;
}
@media (min-width: 600px) {
  .block__contact-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
.block__contact-wrapper .contact__form {
  padding: 0 3em;
  max-width: auto;
}
.block__contact-wrapper .contact__image {
  height: 100%;
}
.block__contact-wrapper .contact__image figure {
  height: 100%;
}
.block__contact-wrapper .contact__image img {
  max-width: 100%;
  height: auto;
}
@media (min-width: 600px) {
  .block__contact-wrapper .contact__image img {
    width: 460px;
    height: 100%;
    object-fit: cover;
  }
}
.block__contact-wrapper form {
  padding: 3.5em 4em;
}
@media (max-width: 768px) {
  .block__contact-wrapper form {
    padding: 20px;
  }
}
.block__contact-wrapper .btn-link {
  color: var(--primary);
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-size: 15px;
  margin: 20px 0;
}
.block__contact-wrapper .btn-link .icon {
  --size: 18px;
  margin-left: 10px;
}
.block__contact .input-group {
  margin-bottom: 1em;
  position: relative;
}
.block__contact .input-group .icon {
  position: absolute;
  top: 0;
  right: 15px;
  margin: auto;
  bottom: 0;
}
.block__contact .heading__title {
  font-weight: 600;
  color: var(--gray-1);
  margin-bottom: 1em;
}
.block__contact .form-control {
  border: 1px solid var(--gray-4);
  border-radius: 0;
  height: 55px;
}
.block__contact .form-control::-webkit-input-placeholder {
  /* Edge */
  color: var(--gray-2);
  font-size: 15px;
  font-weight: 200;
}
.block__contact .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--gray-2);
  font-size: 15px;
  font-weight: 200;
}
.block__contact .form-control::placeholder {
  color: var(--gray-2);
  font-size: 15px;
  font-weight: 200;
}
.block__contact .form-control:focus::-webkit-input-placeholder, .block__contact .form-control:focus::placeholder {
  opacity: 0;
}
.block__contact .form-control__label {
  width: 100%;
  display: block;
  color: var(--gray-3);
  font-weight: 300;
  font-size: 14px;
  padding-left: 3px;
}
.block__contact .btn {
  width: 100%;
  text-align: center;
  justify-content: center;
}

.modal {
  width: var(--width, 450px);
  height: var(--height, 370px);
  border-radius: 6px;
  background-color: white;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  display: none;
}
.modal__body {
  padding: 2em;
}
.modal .form-header {
  margin-top: 0;
  margin-bottom: 20px;
}
.modal .form-control {
  border: 1px solid #DDD;
  margin-bottom: 20px;
  margin-top: 5px;
}
.modal .input-group label {
  font-weight: 500;
  font-size: 12px;
}
.modal .btn-link {
  color: #9e9e9e;
}

.modal-is-active .modal {
  display: block;
  z-index: 60;
  width: 99%;
  margin: auto;
  position: fixed;
  max-width: 470px;
}
.modal-is-active .overlay--modal {
  display: block;
}

.modal__access--big {
  --height: 610px;
}

.cards {
  width: calc(100% / var(--items, 3) - 20px);
  margin: 20px 10px;
  position: relative;
}
@media (max-width: 768px) {
  .cards {
    --items: 1;
  }
}
.cards__image {
  margin: 0;
  max-height: 510px;
  overflow: hidden;
  height: 510px;
}
.cards__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.cards__content {
  padding: 20px;
  color: var(--color, #000);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.cards__content-heading {
  width: 100%;
}
.cards__title {
  font-size: max(1.6em, 1.9vw);
  color: var(--gray-1);
  line-height: 32px;
  margin-bottom: 10px;
}
.cards__description {
  line-height: 18px;
  color: var(--gray-2);
  font-size: max(1em, 1vw);
  margin: 5px 0;
  font-weight: 600;
  margin-bottom: 20px;
}
.cards__features {
  color: var(--gray-2);
  line-height: 24px;
  font-size: clamp(14px, 1vw, 1.5em);
  margin-top: 10px;
  font-weight: 400;
}
.cards__features-item {
  margin-bottom: 10px;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.cards__features-item .icon {
  --size: 20px;
  margin-right: 10px;
}
.cards__flag {
  border-radius: 100px;
  background: var(--primary-light);
  color: var(--primary);
  text-align: center;
  padding: 6px 13px;
  font-size: 12px;
  margin-bottom: 20px;
  display: inline-flex;
  align-items: center;
}
.cards__flag .icon {
  --size: 16px;
  margin-right: 10px;
}

.sidebar {
  width: 100%;
}

.sidebar__search {
  border-radius: 10px;
  background-color: #D8443C;
  color: white;
  padding: 2em 1.5em;
}
.sidebar__search--bottom {
  padding: 1.5em;
}
.sidebar__search .input-group label {
  --color: white;
}

.contact__bar {
  width: 100%;
  height: 60px;
  z-index: 99;
  background-color: var(--primary-dark);
  display: flex;
  align-items: center;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: opacity 0.5s;
  padding: 5px 0;
}
.contact__bar.sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
}
.contact__bar .container {
  align-items: center;
  height: 100%;
}
@media (max-width: 768px) {
  .contact__bar .container {
    justify-content: space-between;
  }
}
.contact__bar .icon {
  fill: white;
  margin-right: 10px;
  --size: 25px;
}
@media (max-width: 768px) {
  .contact__bar .icon {
    margin-right: 0;
  }
}
.contact__bar-logo {
  margin: 0;
}
.contact__bar-logo img {
  width: 70px;
  filter: brightness(0) invert(1);
}
.contact__bar-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: calc(100% / var(--items, 5) );
  color: #fff;
  position: relative;
}
@media (max-width: 768px) {
  .contact__bar-item {
    width: 60px;
  }
}
.contact__bar-item.first {
  border-right: 1px solid #136786;
}
@media (max-width: 768px) {
  .contact__bar-item.first {
    display: none;
  }
}
@media (max-width: 768px) {
  .contact__bar-content {
    display: none;
  }
}
.contact__bar-content--first {
  font-weight: 700;
  font-size: 14px;
}
.contact__bar-label {
  width: 100%;
  font-size: 10px;
  margin-bottom: -5px;
}
.contact__bar-value {
  font-size: 14px;
  line-height: 16px;
  font-weight: 900;
}

.navigation {
  display: flex;
}
@media (max-width: 768px) {
  .navigation {
    flex-wrap: wrap;
    align-content: flex-start;
    height: 100%;
    padding: 1em 2em;
    width: 370px;
    background-color: #fff;
    transform: translate3d(100%, 0, 0);
    transition: transform 0.3s cubic-bezier(0.75, 0, 0.25, 1);
    position: fixed;
    right: 0;
    top: 0;
  }
}
.navigation__handler-menu {
  border: 0;
  background-color: transparent;
  outline: none;
  margin-left: auto;
}
.navigation__handler-menu .icon {
  fill: var(--primary-dark);
  --size: 35px;
}
@media (min-width: 900px) {
  .navigation__handler-menu {
    display: none;
  }
}
.navigation__close {
  outline: none;
  height: 50px;
  width: 50px;
  border: 0;
  display: none;
  background-color: var(--primary-color);
  position: absolute;
  top: 0;
  left: -50px;
  transition: opacity 0.3s ease, background 0.5s;
  opacity: 0;
  cursor: pointer;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  z-index: 9;
}
.navigation__content {
  align-items: center;
  width: 100%;
  display: flex;
}
@media (max-width: 768px) {
  .navigation__content {
    margin-top: 0;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media (max-width: 768px) {
  .navigation__list {
    width: 100%;
  }
}
.navigation__item {
  margin: 0 12px;
  font-size: 15px;
  letter-spacing: 0.75px;
  color: var(--gray-2);
  display: inline-flex;
  align-items: center;
}
@media (max-width: 768px) {
  .navigation__item {
    margin: 0;
    font-size: 15px;
    padding: 12px 0;
  }
}
.navigation__item.btn-login {
  color: var(--primary);
}
.navigation__item.btn-login .icon {
  fill: var(--primary);
}
.navigation__actions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media (min-width: 600px) {
  .navigation__actions {
    margin-left: auto;
  }
}
@media (max-width: 768px) {
  .navigation__actions .navigation__list:last-child {
    margin-top: 30px;
  }
}
.navigation .dropdown__menu--action {
  position: relative;
}
@media (min-width: 600px) {
  .navigation .dropdown__menu--action:hover .dropdown__menu {
    transform: scale(1);
    opacity: 1;
  }
}
.navigation .icon {
  --size: 13px;
  fill: var(--primary-color);
  margin-right: 5px;
}
.navigation .dropdown__menu {
  background-color: white;
  border-radius: 6px;
  position: absolute;
  top: auto;
  left: -6em;
  margin-top: 10px;
  transform: scale(0);
  opacity: 0;
  width: 280px;
  padding-top: 20px;
  border: 1px solid #F7F7FC;
  box-shadow: 0px 6px 8px rgba(51, 51, 51, 0.1);
  z-index: 99;
}
.navigation .dropdown__menu:after {
  content: "";
  top: -21px;
  left: 0;
  right: 0;
  margin: auto;
  width: 1px;
  height: 1px;
  position: absolute;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid #f7f7fc;
}
.navigation .dropdown__menu:before {
  content: "";
  background-color: transparent;
  top: -16px;
  height: 30px;
  width: 100%;
  position: absolute;
}
.navigation .dropdown__menu--bottom {
  background-color: #F7F7FC;
}
@media (min-width: 600px) {
  .navigation .dropdown__menu--bottom {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.navigation .dropdown__menu--bottom .dropdown__menu-description {
  color: var(--primary);
  font-size: 15px;
  line-height: 25px;
  font-weight: 700;
}
.navigation .dropdown__menu--bottom .dropdown__menu-description .icon {
  fill: var(--primary);
  --size: 14px;
}
.navigation .dropdown__menu--bottom .dropdown__menu-item {
  margin-bottom: 10px;
}
.navigation .dropdown__menu--bottom .dropdown__menu-item:last-child {
  margin-bottom: 0;
}
.navigation .dropdown__menu-item {
  padding: 0 20px;
  margin-bottom: 20px;
}
.navigation .dropdown__menu-title {
  color: #14142B;
  font-size: 15px;
  letter-spacing: 0.75px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .navigation .dropdown__menu-title {
    font-size: 14px;
  }
}
.navigation .dropdown__menu-description {
  letter-spacing: 0.75px;
  color: #6E7191;
  font-size: 12px;
}
.navigation__mobile-header {
  width: 100%;
}
@media (min-width: 900px) {
  .navigation__mobile-header {
    display: none;
  }
}
.navigation__mobile-header .dropdown__menu-item {
  padding-left: 0;
}
.navigation__mobile-header .dropdown__menu--bottom {
  background-color: white;
}

.active-menu .navigation__close {
  opacity: 1;
  display: block;
}
.active-menu .navigation__close .icon {
  fill: white;
  margin-right: 0;
}
.active-menu .navigation {
  transform: translate3d(25%, 0, 0);
  z-index: 999;
}
.active-menu .overlay-menu {
  display: block;
}

.results__banner {
  padding: 1.6em 0;
  background-color: var(--primary-dark);
}
.results__banner-title {
  color: white;
  font-size: 1.6em;
  font-size: 24px;
  letter-spacing: 1px;
}

.property__list-container .container {
  display: grid;
  grid-template-columns: 19em auto;
  grid-gap: 40px;
}
.property__list-container .property__list-content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
}
.property__list-container .property__list-content[data-visualization=list] .cards {
  --items: 1;
  display: grid;
  grid-template-columns: auto 1fr;
  border: 0;
  border-radius: 6px;
  margin: 0 0 2em;
}
.property__list-container .property__list-content[data-visualization=list] .cards:hover {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.12);
}
.property__list-container .property__list-content[data-visualization=list] .cards__items {
  justify-content: flex-start;
  font-size: 13px;
}
.property__list-container .property__list-content[data-visualization=list] .cards__items span {
  margin-right: 19px;
}
.property__list-container .property__list-content[data-visualization=list] .cards__title {
  font-size: 21px;
}
.property__list-container .property__list-content[data-visualization=list] .cards__figure {
  width: 290px;
  height: 214px;
  overflow: hidden;
}
.property__list-container .property__list-content[data-visualization=list] .cards__content {
  padding: 0 20px;
}
.property__list-container .property__list-content[data-visualization=list] .cards__flag {
  display: none;
}

.info__tecnicas .heading__title {
  width: 100%;
  font-weight: 700;
  padding-bottom: 15px;
}
.info__tecnicas-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
}
@media (max-width: 768px) {
  .info__tecnicas-content {
    grid-template-columns: 1fr;
  }
}
.info__tecnicas-title {
  color: var(--primary-color);
  font-size: 19px;
  margin: 0px 0px 20px;
  display: block;
  font-weight: 600;
}
.info__tecnicas-item {
  padding: 15px 0;
  border-bottom: 1px solid #EFF0F6;
  font-size: 14px;
  color: #4E4B66;
  display: inline-flex;
  width: 100%;
}
.info__tecnicas-item span {
  font-weight: 600;
  max-width: 215px;
  min-width: 180px;
  display: inline-flex;
  vertical-align: middle;
}

.results__summary .heading__title {
  --font-size: 24px;
  padding-bottom: 13px;
  color: var(--primary-color);
}

.single__building-hero {
  background-color: var(--primary-dark);
  padding-top: 2em;
}
.single__building-banner {
  display: flex;
  flex-wrap: wrap;
}
.single__building-banner .slick-slide {
  height: clamp(30em, 60vh, 40em);
  margin: 0 20px;
  position: relative;
}
.single__building-banner .slick-slide:before {
  opacity: 0;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 1.2s;
}
.single__building-banner .slick-slide:not(.slick-center):before {
  opacity: 0.6;
}
.single__building-banner .slick-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.single__building-info {
  padding: 2em 1em;
  color: white;
}
@media (min-width: 600px) {
  .single__building-info .container {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: minmax(auto, 480px) minmax(auto, 365px) 1.3fr;
    align-items: center;
  }
}
.single__building-info-flag {
  background-color: var(--primary-light);
  color: var(--primary);
  text-align: center;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 100px;
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 10px;
}
.single__building-info-flag .icon {
  --size: 18px;
}
.single__building-info-heading {
  text-align: center;
}
.single__building-info-logo {
  margin: 0 auto;
  text-align: center;
}
.single__building-info-logo img {
  height: 80px;
}
@media (max-width: 768px) {
  .single__building-info-logo {
    margin: 0;
    display: inline-flex;
  }
}
.single__building-info-title {
  font-size: 2em;
}
.single__building-info-description {
  padding-bottom: 20px;
  margin: 0;
  margin-top: 10px;
  font-weight: 600;
}
.single__building-info-price {
  font-size: 15px;
  padding: 1.4em 0.5em;
  font-weight: 200;
  color: var(--gray-6);
}
@media (min-width: 600px) {
  .single__building-info-price {
    border-left: 1px solid #1b6986;
    padding-left: 25px;
  }
}
.single__building-info-price--value {
  color: white;
  font-size: 2.2em;
}
.single__building-info-price .btn-link {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  position: relative;
}
.single__building-info-price .btn-link svg {
  margin-right: 10px;
}
.single__building-info-price .btn-link:hover .payments {
  display: block;
}
.single__building-info-price .btn-link .payments {
  background-color: white;
  position: absolute;
  top: 30px;
  width: 280px;
  left: -30px;
  padding: 20px;
  display: none;
  z-index: 10;
  box-shadow: 0px 6px 8px rgba(51, 51, 51, 0.1);
}
.single__building-info-price .btn-link .payments:after {
  content: "";
  top: -21px;
  left: 0;
  right: 0;
  margin: auto;
  width: 1px;
  height: 1px;
  position: absolute;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid #f7f7fc;
}
.single__building-info-price .btn-link .payments:before {
  content: "";
  background-color: transparent;
  top: -16px;
  height: 30px;
  width: 100%;
  position: absolute;
}
.single__building-info-price .btn-link .payments__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
.single__building-info-price .btn-link .payments__item {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  font-size: 13px;
  letter-spacing: 0.75px;
  color: #6E7191;
  margin-bottom: 20px;
}
.single__building-info-price .btn-link .payments .icon {
  --size: 15px;
  fill: var(--primary-dark);
}
.single__building-info .single__page-list {
  padding: 1.6em 0;
  width: 100%;
  justify-content: center;
}
.single__building-info .single__page-list .icon {
  --size: 20px;
}
@media (min-width: 1800px) {
  .single__building-info .single__page-list .icon {
    --size: 50px;
  }
}
.single__building-info .single__page-label {
  font-size: 12px;
  margin: 5px 0;
}
@media (min-width: 1800px) {
  .single__building-info .single__page-label {
    font-size: 18px;
  }
}
.single__building-info .single__page-item {
  margin-right: 0;
  margin-top: 0;
}
.single__building-info .btn {
  margin-top: 10px;
}
@media (max-width: 768px) {
  .single__building-info .btn {
    width: 100%;
    text-align: center;
    justify-content: center;
    --padding: 15px 20px;
    margin: 0 1em;
  }
}
@media (min-width: 1800px) {
  .single__building-info .btn {
    --padding: 15px 40px;
    --font-size: 20px;
  }
}
.single__building-features {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 768px) {
  .single__building-features {
    width: 100%;
  }
}
.single__building-features-item {
  font-size: 13px;
  text-align: center;
  font-size: 13px;
  padding: 0 10px;
}
.single__building-features-item .icon {
  fill: white;
}
.single__building-actions {
  margin-top: 2em;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
.single__building-actions .btn__link {
  color: var(--gray-2);
  margin-left: 20px;
  display: inline-flex;
  align-items: center;
}
@media (max-width: 768px) {
  .single__building-actions .btn__link {
    margin-top: 15px;
  }
}
.single__building-actions .btn__link svg {
  margin-right: 15px;
}

.single__building-about {
  padding-bottom: 1em;
}
.single__building-about .heading__title {
  font-size: max(2em, 2vw);
  margin-top: 0;
  margin-bottom: 1em;
  margin-top: 1em;
  font-weight: 700;
}
.single__building-about-date {
  display: flex;
  flex-wrap: wrap;
  color: var(--gray-2);
  font-size: 14px;
}
.single__building-about-container {
  width: 100%;
  margin-top: 3em;
}
@media (min-width: 600px) {
  .single__building-about-container {
    display: grid;
    grid-gap: 3em;
    grid-template-columns: 1fr 2fr;
  }
}
.single__building-about-description {
  color: var(--gray-2);
  font-size: 16px;
  line-height: 28px;
  width: 100%;
}
.single__building-about-description:not(.active) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.single__building-about-description.active ~ .show-more .icon {
  transform: rotate(180deg);
}
.single__building-about .show-more {
  display: block;
  text-align: center;
  position: relative;
}
.single__building-about .show-more span {
  font-weight: 600;
  display: inline-block;
  background-color: white;
  padding: 4px 10px;
  color: var(--gray-2);
  font-size: 12px;
  z-index: 2;
  position: relative;
  border-top: 0;
}
.single__building-about .show-more:before {
  content: "";
  text-align: center;
  top: -30px;
  bottom: 0;
  left: 0;
  width: 100%;
  position: absolute;
  z-index: 9;
  height: 30px;
  background-image: -moz-linear-gradient(top, transparent, black);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, transparent), color-stop(1, #fff));
  z-index: 1;
}
.single__building-about .show-more .icon {
  display: block;
  text-align: center;
  margin: auto;
  fill: var(--gray-2);
  --size: 12px ;
}
.single__building-about-figure img {
  margin: 0 auto;
  max-width: 100%;
}
.single__building-about-figure:not(.active) img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.single__building-about-figure.active img {
  max-width: 24em;
}
.single__building-about .differentials {
  margin: 20px 0;
}
.single__building-about .differentials .icon {
  fill: var(--primary);
  margin-right: 10px;
  --size: 13px;
}
.single__building-about .differentials__list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  grid-column-gap: 5px;
  max-width: 100%;
  grid-row-gap: 25px;
}
.single__building-about .differentials__item {
  color: var(--gray-2);
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.single__building-about .differentials__item strong {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

.maps__tags {
  width: 100%;
  position: relative;
}
.maps__tags-list {
  width: 100%;
  white-space: nowrap;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.maps__tags-list::-webkit-scrollbar {
  display: none;
}
.maps__tags-description {
  font-size: 12px;
  color: var(--gray-1);
}
.maps__tags-item {
  font-size: 12px;
  border: 1px solid var(--primary-dark);
  color: var(--primary-dark);
  border-radius: 3px;
  padding: 5px 20px;
  display: inline-flex;
  align-items: center;
  margin: 10px 0;
  font-weight: 600;
}
.maps__tags-button {
  background-color: transparent;
  border: 0;
  position: absolute;
  top: 10px;
  bottom: 0;
  margin: auto;
  outline: none;
}
@media (max-width: 768px) {
  .maps__tags-button {
    display: none;
  }
}
.maps__tags-button--right {
  right: -3em;
}
.maps__tags-button--left {
  left: -3em;
}
.maps__tags-button--left .icon {
  transform: rotate(180deg);
}

#map {
  margin: 2em 0;
  width: 100%;
}
#map iframe {
  width: inherit;
}

.neighborhood {
  padding: 3em 0;
}
.neighborhood .container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}
@media (max-width: 768px) {
  .neighborhood .container {
    grid-template-columns: 1fr;
  }
}
.neighborhood__image {
  text-align: center;
}
.neighborhood__image img {
  width: 400px;
  margin: 0 auto;
}
@media (max-width: 599px) {
  .neighborhood__image img {
    width: 330px;
  }
}
.neighborhood .heading__description {
  font-size: 14px;
  letter-spacing: 0.75px;
  color: #4E4B66;
  margin-top: 2em;
  line-height: 28px;
}

.contact--single__enterprise {
  padding: 2em 0;
  width: 100%;
  background-color: var(--primary-color-dark);
}
.contact--single__enterprise .form-control__label {
  width: 100%;
  margin: 10px 0;
  color: white;
  font-weight: 400;
}
.contact--single__enterprise .heading__title {
  text-align: center;
  width: 100%;
  color: white;
  border-bottom: 0;
  position: relative;
  margin-bottom: 2em;
}
@media (max-width: 768px) {
  .contact--single__enterprise .heading__title {
    --font-size: 1.2em;
  }
}
.contact--single__enterprise .heading__title:after {
  content: "";
  position: absolute;
  bottom: -15px;
  height: 1px;
  width: 310px;
  background-color: #b7b7b7;
  left: 0;
  right: 0;
  margin: auto;
}

.building-navigation {
  border-bottom: 2px solid var(--gray-3);
  position: relative;
  background: #fff;
  z-index: 1;
  width: 100%;
  white-space: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 1em 0;
  position: sticky;
  top: 0;
}
.building-navigation .container {
  display: flex;
  justify-content: space-between;
}
.building-navigation::-webkit-scrollbar {
  display: none;
}
.building-navigation__list {
  display: inline-block;
  text-align: center;
}
.building-navigation__item {
  padding: 10px 20px;
  color: #858585;
  font-size: 20px;
  font-weight: 300;
  display: block;
}
.building-navigation__item.active {
  border-bottom: 3px solid var(--primary);
  color: var(--primary);
  font-weight: 600;
}

.mini-banner {
  padding: 10em 0;
  background-size: cover;
  text-align: center;
}
.mini-banner .heading__title {
  max-width: 15em;
  color: white;
  text-align: center;
  font-size: max(2.2em, 2.6vw);
  margin: 0 auto;
  line-height: 140%;
  letter-spacing: 0.16em;
  text-transform: uppercase;
}

.disclaimer {
  padding: 3em 0;
  text-align: center;
}
.disclaimer .heading__title {
  font-weight: 400;
  padding: 1em 2em;
  line-height: 130%;
}
@media (max-width: 768px) {
  .disclaimer .heading__title {
    font-size: 27px;
    padding: 0;
  }
}
.disclaimer__description {
  color: var(--gray-2);
  font-size: 18px;
  line-height: 28px;
}
.disclaimer__big-text {
  font-size: 5.5em;
  font-weight: 600;
  color: var(--gray-1);
  line-height: 130%;
  margin-bottom: 20px;
}
.disclaimer .block__list {
  justify-content: center;
  max-width: 550px;
  margin: 0 auto;
}
.disclaimer .block__list-item {
  display: inline-flex;
  margin: 0 10px;
}

.quem__somos-content {
  color: var(--gray-2);
  font-size: 16px;
  line-height: 28px;
  padding: 1em 10em;
}
@media (max-width: 768px) {
  .quem__somos-content {
    padding: 0;
  }
}

.quem__somos-image {
  width: 100%;
  text-align: center;
  margin: 2em 0;
}
.quem__somos-image img {
  height: auto;
}

.btn-disclaimer {
  display: block;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 1.9em;
  text-transform: inherit;
  padding: 1em 16px;
  width: 100%;
  text-align: center;
  background: var(--primary-dark);
  margin-bottom: 2em;
}
.btn-disclaimer svg {
  display: inline-block;
  vertical-align: middle;
}

.section__about {
  margin: 2em 0;
}
.section__about .heading__title {
  padding-bottom: 10px;
  --font-size: 2.4em;
  font-weight: 300;
}
.section__about-content {
  display: grid;
  grid-gap: 2em;
  padding: 2em 0;
}
@media (min-width: 600px) {
  .section__about-content {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1200px) {
  .section__about-content {
    grid-template-columns: auto max-content;
  }
}
.section__about-description {
  font-size: 1em;
  color: var(--cinza);
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.75px;
  text-align: left;
}
.section__about-figure img {
  width: 25em;
}
@media (max-width: 768px) {
  .section__about-figure img {
    width: 100%;
  }
}

.section__cta {
  background-image: url("/banner-cta.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 3em 2em;
  color: white;
}
.section__cta-title {
  width: 100%;
  font-size: 2.9em;
}
.section__cta-description {
  width: 100%;
  margin-bottom: 2em;
  font-size: 0.9em;
}
.section__cta .container {
  justify-content: center;
  text-align: center;
}
.section__cta-btn {
  --btn-background: transparent;
  --btn-border: 1px solid white;
}

.section__missao {
  margin: 2em 0 6em;
}
.section__missao-item {
  width: calc(100% / var(--items, 2) - 20px);
  margin: 10px;
  display: flex;
  align-items: center;
}
@media (max-width: 768px) {
  .section__missao-item {
    flex-wrap: wrap;
    --items: 1;
    margin: 25px 10px;
  }
}
.section__missao-image {
  padding: 0 10px;
}
.section__missao-image img {
  max-width: 80px;
}
.section__missao-content {
  padding: 0 2em;
  flex-basis: 450px;
}
@media (max-width: 768px) {
  .section__missao-content {
    padding: 0;
  }
}
.section__missao-title {
  font-size: 20px;
  letter-spacing: 1px;
  color: #14142B;
  font-weight: 600;
  margin-bottom: 5px;
}
.section__missao-description {
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.75px;
  color: var(--cinza);
  margin: 0;
}

.timeline {
  position: relative;
}
.timeline:before {
  content: "";
  width: 100%;
  height: 5px;
  background-color: #000;
  top: 0.6em;
  position: absolute;
}

.internal__page {
  background-size: contain;
  background-repeat: no-repeat;
}
.internal__page .heading__description {
  padding: 2em 0;
  max-width: 40em;
  color: var(--gray-2);
}
@media (max-width: 768px) {
  .internal__page .heading__description {
    padding: 1em;
  }
}
.internal__page--assistencia {
  background-image: url("/decorate-2.png");
  background-size: 33em;
  background-position: top 2em right;
  background-repeat: no-repeat;
}
@media (max-width: 599px) {
  .internal__page--assistencia {
    background-position: top 0 right;
    background-size: 20em;
  }
}
@media (max-width: 768px) {
  .internal__page--assistencia .heading__description {
    background-color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 20px;
    margin-top: 12em;
  }
}
.internal__page--aprovacao {
  padding: 3em;
  text-align: center;
}
.internal__page--aprovacao .heading__title {
  border: 0;
}
.internal__page--aprovacao .heading__description {
  text-align: center;
  margin: auto;
}
.internal__page-column {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  position: relative;
  justify-content: center;
}
@media (max-width: 768px) {
  .internal__page-column {
    margin-top: 2em;
  }
}
.internal__page-column--figure {
  content: "";
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}
.internal__page-content {
  width: 100%;
}
@media (min-width: 1200px) {
  .internal__page-content {
    grid-gap: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
.internal__page .contact {
  padding: 1em 2em;
}
@media (max-width: 768px) {
  .internal__page .contact {
    margin: auto;
    padding: 1em;
  }
}
@media (min-width: 1200px) {
  .internal__page .contact {
    margin: 0;
  }
}
.internal__page .contact .checkbox-type-contact {
  --gutter: 10px;
  margin: 5px;
}
.internal__page .internal__page-info {
  width: 500px;
  border-radius: 5px;
  margin: 0 auto;
}
.internal__page .internal__page-info-header {
  background-color: var(--primary-dark);
  color: white;
  padding: 1em 2em;
}
.internal__page .internal__page-info-content {
  padding: 1em 2em;
  background-color: #F7F7FC;
}
.internal__page .internal__page-info-list {
  color: #4E4B66;
  padding: 10px 5px;
  font-size: 14px;
}
.internal__page .internal__page-info-list .icon {
  --size: 14px;
  margin-right: 5px;
  fill: #4E4B66;
}

.internal__page-social {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: 3em;
}
.internal__page-social-header {
  width: 100%;
  color: var(--primary-dark);
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: 700;
}
.internal__page-social .icon {
  --size: 35px ;
}

.form-recovery {
  background-color: #F7F7FC;
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  border: 1px solid #ddd;
  width: 400px;
  max-width: 100%;
  margin: 2em auto;
  padding: 40px;
}
.form-recovery .form-control__label {
  color: #6b6b6b;
  font-weight: 400;
  margin-bottom: 10px;
}
.form-recovery .checkbox-type-contact {
  width: 100%;
}
.form-recovery .btn {
  margin: 20px auto;
}

.maps {
  width: 100%;
  margin-top: 3em;
}

.tabelas__content {
  width: 100%;
  display: grid;
  flex-wrap: wrap;
  padding: 2em;
  grid-template-columns: repeat(5, 1fr);
}

.tabela__actions {
  margin-left: auto;
}

.card-tables__item {
  text-align: center;
  font-size: 13px;
  margin: 15px 0;
}
.card-tables__item svg {
  margin: auto;
}

.card-tables__title {
  margin-top: 10px;
}

.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  background-color: white;
}

.loading.active {
  display: block;
}

.actions-page {
  margin-left: auto;
}

.actions-page {
  padding-bottom: 20px;
  border-bottom: 1px solid #DDD;
}

.btn-default {
  background-color: #930c1a;
  padding: 7px 22px;
  border-radius: 4px;
  border: 0;
  color: white;
  font-weight: 600;
  cursor: pointer;
  margin-top: 30px;
  margin-left: auto;
  display: block;
}

.tabela-page--header {
  margin-top: 4.4em;
}

.single__page-content {
  padding: 2em 5em 2px 1em;
}
.single__page .container {
  display: grid;
  grid-template-columns: auto max-content;
}
.single__page-title {
  color: var(--primary-color);
  font-size: 36px;
  font-weight: 400;
  line-height: 48px;
  position: relative;
}
.single__page-ref {
  color: #A0A3BD;
  font-size: 11px;
  text-align: right;
  border-bottom: 1px solid #EFF0F6;
  padding-bottom: 10px;
}
.single__page-description {
  font-weight: 300;
  line-height: 38px;
  color: #4E4B66;
  display: grid;
  grid-template-columns: max-content auto;
  align-items: flex-start;
  margin: 15px 0;
}
.single__page-description-text {
  font-size: 16px;
  line-height: 25px;
  max-width: 520px;
}
.single__page-description-text p {
  margin: 0;
}
.single__page-description .icon {
  --size: 15px;
  margin-right: 10px;
  margin-top: 7px;
}
.single__page-description .btn {
  --padding: 0 20px;
  --font-size: 10px;
  font-weight: 600;
  height: 25px;
  line-height: 25px;
  margin-left: 10px;
}
.single__page-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  text-align: center;
}
.single__page-list .icon {
  margin: 0 auto;
  --size: 35px;
}
.single__page-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-right: 1.3em;
  margin-top: 1.3em;
}
@media (max-width: 768px) {
  .single__page-item {
    width: calc(100% / 4);
  }
}
.single__page-label {
  width: 100%;
  margin: 15px 0;
  font-size: 16px;
  line-height: 19px;
  color: #4E4B66;
}
.single__page .about__property {
  margin-top: 2em;
}
.single__page .about__property .heading__title {
  border-bottom: 0;
}
.single__page .about__property-description {
  font-size: 15px;
  line-height: 34px;
  color: #4E4B66;
}
.single__page .about__property .btn {
  --btn-background: transparent;
  --btn-color: var(--primary-color-light);
  --btn-border: 1px solid var(--primary-color-light);
  --padding: 15px 25px;
  font-weight: 600;
  align-items: center;
  margin-top: 2em;
}
.single__page .about__property .btn .icon {
  fill: var(--primary-color-light);
  --size: 14px;
  margin-right: 10px;
}
.single__page .sidebar {
  width: 24em;
  padding: 20px;
}

.single-banner {
  position: relative;
}

.btn-open-gallery {
  --btn-color: var(--primary-color-light);
  --btn-background: white;
  display: inline-flex;
  align-items: center;
  position: absolute;
  left: 2em;
  bottom: 20px;
  --font-size: 12px;
}
.btn-open-gallery .icon {
  fill: var(--primary-color-light);
  margin-right: 5px;
  --size: 15px;
}

.single__page-more-info {
  font-size: 12px;
  color: #4E4B66;
  display: inline-flex;
  align-items: center;
  margin: 30px 0;
  font-weight: 600;
}
@media (min-width: 1800px) {
  .single__page-more-info {
    align-content: center;
  }
}
.single__page-more-info .icon {
  fill: #4E4B66;
  --size: 10px;
  margin-right: 5px;
}

.single__sidebar {
  background-color: #F7F7FC;
}
.single__sidebar-header {
  color: white;
  background-color: var(--primary-color);
  padding: 15px 30px;
  font-weight: 600;
}
.single__sidebar-price {
  padding: 20px 30px;
  font-family: "Lato", sans-serif;
}
.single__sidebar-price-label {
  font-size: 13px;
  color: #6E7191;
  margin-bottom: 5px;
}
.single__sidebar-price-value {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 2.8em;
}
.single__sidebar-list {
  padding: 15px 30px;
}
.single__sidebar-item {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
}
.single__sidebar-value {
  font-weight: 600;
}
.single__sidebar .btn-group {
  padding: 0 30px;
}
.single__sidebar .btn-phone,
.single__sidebar .btn-whatsapp {
  width: 100%;
  --padding: 15px 20px;
  justify-content: center;
}
.single__sidebar .btn-phone .icon,
.single__sidebar .btn-whatsapp .icon {
  --size: 18px;
  margin-right: 5px;
}
.single__sidebar .btn-phone {
  --btn-background: transparent;
  --btn-border: 1px solid var(--primary-color-light);
  --btn-color: var(--primary-color-light);
  align-items: center;
  margin-top: 20px;
}
.single__sidebar .btn-phone .icon {
  fill: var(--primary-color-light);
  --size: 12px;
}
.single__sidebar .btn-whatsapp {
  --btn-background: #00BA88;
}
.single__sidebar .btn-whatsapp .icon {
  fill: white;
}

.arch-slider {
  margin: 0;
  background-color: white;
  padding: 0;
}

.arc-images {
  position: relative;
}
.arc-images:before {
  content: "";
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0;
  z-index: 1;
}

a.arc-link:hover .arc-images:before {
  background: rgba(247, 247, 247, 0.14);
}

.architecturalmillwork-lyt .slick-lightbox-close {
  color: #006e9e;
  font-size: 26px;
  width: 38px;
  height: 44px;
  margin-top: 30px;
  margin-right: 80px;
  right: 0;
  top: 2px;
}

.arch-slider .slick-arrow {
  position: absolute;
  width: 100px;
  height: 100px;
  top: 50%;
  margin-top: -55px;
  z-index: 111;
  padding: 20px 38.5px;
  cursor: pointer;
}

.arch-slider .slick-arrow.next {
  right: 0;
}

.arch-slider .slick-arrow svg {
  -webkit-filter: drop-shadow(0 1px 0.15px #b2b2b2);
  filter: drop-shadow(0 1px 0.15px #b2b2b2);
  display: inline-block;
}

.slideshow-arrow {
  fill: #e8e8e8;
}

.architecturalmillwork-lyt .slick-lightbox-inner {
  background-color: #e8e8e8;
  z-index: 1111;
}

.architecturalmillwork-lyt .slick-lightbox-inner .slick-arrow {
  z-index: 111;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 27px;
  margin-top: -50px;
}
.architecturalmillwork-lyt .slick-lightbox-inner .slick-arrow:before {
  display: none;
}

.architecturalmillwork-lyt .slick-lightbox-inner .slick-prev {
  left: 0;
}

.architecturalmillwork-lyt .slick-lightbox-inner .slick-next {
  right: 0;
  transform: rotate(180deg);
}

.gallery {
  padding: 2em 0;
  margin-top: 3em;
}
.gallery .heading {
  text-align: center;
}
.gallery .heading__title {
  font-weight: bold;
}
.gallery .heading__description {
  color: var(--gray-2);
  font-size: 16px;
}
.gallery__grid {
  width: 100%;
  margin-top: 2em;
  position: relative;
}
.gallery__grid:before, .gallery__grid:after {
  content: "";
  top: 0;
  bottom: 0;
  margin: auto;
  width: 5em;
  height: 100%;
  background-color: #ffffffd6;
  position: absolute;
  z-index: 99;
}
.gallery__grid:before {
  left: 0;
}
.gallery__grid:after {
  right: 0;
}
.gallery__grid img {
  max-width: 100%;
  height: 28em;
  object-fit: cover;
}
.gallery-title {
  margin-bottom: 4em;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 22px;
}
.gallery-container {
  width: 90%;
  margin-top: 2em;
  margin-bottom: 0em;
}
@media (max-width: 768px) {
  .gallery-container {
    flex-wrap: wrap;
  }
}
.gallery .section-title {
  width: 100%;
  margin-bottom: 1em;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.6em;
  margin-top: 0;
}
.gallery-indice {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  .gallery-indice {
    white-space: nowrap;
    overflow-x: auto;
    padding: 15px;
  }
}
.gallery-indice__link {
  padding: 5px 15px;
  border-radius: 5px;
  margin: 5px;
  border: 1px solid transparent;
  color: var(--gray-2);
}
.gallery-indice__link.active {
  color: var(--primary);
  border: 1px solid var(--primary);
}

.gallery--all .slick-slide {
  margin: 0 20px;
}
.gallery--all .slick-slider {
  display: none;
  transition: all 1.2s;
  width: 100%;
}
.gallery--all .slick-slider.active {
  display: block;
}

.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.gallery__plantas {
  width: 100%;
  padding: 2em 0;
}
.gallery__plantas .heading__title {
  text-align: center;
}

.gallery__plantas-list {
  width: 100%;
  margin: 3em auto;
  position: relative;
  outline: none;
}
.gallery__plantas-list:before, .gallery__plantas-list:after {
  content: "";
  top: 0;
  bottom: 0;
  margin: auto;
  width: 13em;
  height: 100%;
  background-color: #ffffffd6;
  position: absolute;
  z-index: 99;
}
.gallery__plantas-list:before {
  left: 0;
}
.gallery__plantas-list:after {
  right: 0;
}
.gallery__plantas-list .slick-slide {
  margin: 0 20px;
}
.gallery__plantas-list .slick-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.gallery__plantas-list .slick-arrow svg {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}
.gallery__plantas-list .slick-arrow.prev {
  left: -30px;
}
.gallery__plantas-list .slick-arrow.next {
  right: 0;
}
.gallery__plantas-list img {
  max-width: 430px;
  margin: 0 auto;
}

.slick-slide {
  outline: 0;
}

.footer {
  background-color: var(--primary-dark);
  color: white;
}
@media (max-width: 768px) {
  .footer__column {
    padding: 20px;
  }
}
@media (min-width: 600px) {
  .footer__column:last-child {
    grid-column-end: none;
  }
}
.footer__column.first {
  text-align: center;
}
.footer__column.first .footer__column-title {
  text-align: center;
  font-size: 13px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.footer__column-title {
  color: var(--primary-light);
  font-weight: 500;
  margin-bottom: 25px;
}
.footer__column-item {
  font-size: 14px;
  line-height: 160%;
  padding: 10px 0;
  display: block;
}
.footer__column-item a {
  display: inline-flex;
  align-items: center;
}
.footer__column-item a:hover {
  text-decoration: underline;
}
.footer__column-content {
  font-size: 14px;
  line-height: 160%;
  color: var(--gray-6);
}
.footer__column-logo {
  text-align: center;
}
.footer__column-logo img {
  width: 12em;
  filter: brightness(0) invert(1);
}
.footer__column .icon {
  --size: 25px;
  fill: white;
  margin-right: 10px;
}
.footer .container {
  display: grid;
  column-gap: 80px;
  grid-template-columns: repeat(auto-fit, minmax(var(--width, 120px), 1fr));
  width: 100%;
  padding: 4em 0 2em;
}
@media (max-width: 768px) {
  .footer .container {
    grid-template-columns: 1fr;
  }
}
.footer .copyright {
  display: block;
  width: 100%;
  text-align: center;
  margin-bottom: 2em;
  color: #D9DBE1;
  font-size: 13px;
}