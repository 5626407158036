.navigation {
  display: flex;
  @include for-size(tablet-portrait-down) {
    flex-wrap: wrap;
    align-content: flex-start;
    height: 100%;
    padding: 1em 2em;
    width: 370px;
    background-color: #fff;
    transform: translate3d(100%,0,0);
    transition: transform .3s cubic-bezier(.75,0,.25,1);
    position: fixed;
    right: 0;
    top: 0;
  }
  &__handler-menu {
    border: 0;
    background-color: transparent;
    outline: none;
    margin-left: auto;
    .icon {
      fill: var(--primary-dark);
      --size: 35px;
    }
    @include for-size(tablet-landscape-up) {
      display: none;
    }
  }
  &__close {
    outline: none;
    height: 50px;
    width: 50px;
    border: 0;
    display: none;
    background-color: var(--primary-color);
    position: absolute;
    top: 0;
    left: -50px;
    transition: opacity .3s ease,background .5s;
    opacity: 0;
    cursor: pointer;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    z-index: 9;
  }

  &__content {
    align-items: center;
    width: 100%;
    display: flex;

    @include for-size(tablet-portrait-down) {
      margin-top: 0;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  &__list {
    @include for-size(tablet-portrait-down) {
      width: 100%;
    }
  }
  &__item {
    margin: 0 12px;
    font-size: 15px;
    letter-spacing: 0.75px;
    color: var(--gray-2);
    display: inline-flex;
    align-items: center;
    @include for-size(tablet-portrait-down) {
      margin: 0;
      font-size: 15px;
      padding: 12px 0;
    }
    &.btn-login {
      color: var(--primary);
      .icon {
        fill: var(--primary);
      }
    }
  }
  &__actions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    @include for-size(tablet-portrait-up) {
      margin-left: auto;
    }
    .navigation__list:last-child {
      @include for-size(tablet-portrait-down) {
        margin-top: 30px;
      }
    }
  }

  .dropdown__menu--action {
    position: relative;
    @include for-size(tablet-portrait-up) {
      &:hover {
        .dropdown__menu {
          transform: scale(1);
          opacity: 1
        }
      }
    }
  }
  .icon{
    --size: 13px;
    fill: var(--primary-color);
    margin-right: 5px;
  }

  .dropdown__menu {
    background-color: white;
    border-radius: 6px;
    position: absolute;
    top: auto;
    left: -6em;
    margin-top: 10px;
    transform: scale(0);
    opacity: 0;
    width: 280px;
    padding-top: 20px;
    border: 1px solid #F7F7FC;
    box-shadow: 0px 6px 8px rgba(51, 51, 51, 0.1);
    z-index: 99;
    &:after {
      content: "";
      top: -21px;
      left: 0;
      right: 0;
      margin: auto;
      width: 1px;
      height: 1px;
      position: absolute;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      border-top: 10px solid transparent;
      border-bottom: 10px solid #f7f7fc;
    }
    &:before {
      content: '';
      background-color: transparent;
      top: -16px;
      height: 30px;
      width: 100%;
      position: absolute;
    }
    &--bottom {
      background-color: #F7F7FC;
      @include for-size(tablet-portrait-up) {
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .dropdown__menu-description {
        color: var(--primary);
        font-size: 15px;
        line-height: 25px;
        font-weight: 700;
        .icon{
          fill: var(--primary);
          --size: 14px;
        }
      }
      .dropdown__menu-item {
        margin-bottom: 10px;

        &:last-child{
          margin-bottom: 0;
        }
      }
    }
    &-item {
      padding: 0 20px;
      margin-bottom: 20px;
    }
    &-title {
      color: #14142B;
      font-size: 15px;
      letter-spacing: 0.75px;
      font-weight: 600;
      @include for-size(tablet-portrait-down) {
        font-size: 14px;
      }
    }
    &-description {
      letter-spacing: 0.75px;
      color: #6E7191;
      font-size: 12px;
    }
  }

  &__mobile-header {
    width: 100%;
    @include for-size(tablet-landscape-up) {
      display: none;
    }
    .dropdown__menu-item {
      padding-left: 0;
    }
    .dropdown__menu--bottom {
      background-color: white
    }
  }
}

.active-menu {
  .navigation__close {
    opacity: 1;
    display: block;
    .icon {
      fill: white;
      margin-right: 0;
    }
  }
  .navigation{
    transform: translate3d(25%,0,0);
    z-index: 999;
  }
  .overlay-menu {
    display: block;
  }
}
