.search {
  display: flex;
  flex-wrap: wrap;
  width: var(--width, 100%);
  padding: 20px;

  form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    background-color: white;
  }
  .input-group {
    --width: 100%;
    border-right: 2px solid #f1f3f5;
    @include for-size(tablet-portrait-up) {
      --width: 300px;
    }
  }

  .btn{
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      margin-left: 15px;
    }
  }
}

.input-group__btn-wrapper {
  @include for-size(tablet-portrait-down) {
    width: 100%;
  }
}
